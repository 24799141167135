<template>
  <picture :style="{ fontSize: `${size}px` }" :title="title">
    <span>
      <b-icon :icon="icon" size="is-small" />
    </span>
  </picture>
</template>

<script>
export default {
  name: "PostIcon",
  props: {
    size: { type: Number, default: 40 },
    cssClass: { type: String, default: "" },
    title: { type: String, default: "System" },
    icon: { type: String, default: "info-circle" }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

picture {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid $grey-light;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  background: $white;
  user-select: none;

  & > span {
    font-family: "Quicksand", sans-serif;
    font-size: 0.4em;
    font-weight: 500;
    line-height: 1;
    color: $grey;
  }
}
</style>
